import decode from 'jwt-decode';
import jwtDecode from 'jwt-decode';
import {
  UserState,
  UserActionTypes,
  ClearAuthAction,
  LoginAction,
} from './types/user';

// Actions
const USER_LOGGED_IN = 'ori-portal/user/USER_LOGGED_IN';
const CLEAR_AUTH = 'ori-portal/user/CLEAR_AUTH';
const UPDATE_REFRESH_TOKEN: any = 'ori-portal/user/UPDATE_REFRESH_TOKEN';
const UPDATE_TOKEN: any = 'ori-portal/user/UPDATE_PUSH_TOKEN';
const SHOW_SIDEBAR: any = 'ori-portal/user/SHOW_SIDEBAR';

const checkAuth = (token: string | null, refreshToken: string | null) => {
  if (!token || !refreshToken) {
    return { user: null };
  }

  try {
    const { exp }: any = decode(refreshToken);
    const { id, username, auth }: any = decode(token);

    if (exp < new Date().getTime() / 1000) {
      return { user: null };
    }

    return { user: { id, username, user: { id, username }, auth } };
  } catch (e) {
    return { user: null };
  }
};

// const token = localStorage.getItem('token');
// const refreshToken = localStorage.getItem('refreshToken');

// const temp = checkAuth(token, refreshToken);

// const initialState: UserState = {
//   token,
//   refreshToken,
//   data: temp.user,
// };

const initialState: UserState = {
  token: null,
  refreshToken: null,
  data: null,
  showSideBar: false,
};

// Reducer
export default function reducer(
  state = initialState,
  action: UserActionTypes,
): UserState {
  switch (action.type) {
    case USER_LOGGED_IN: {
      const { user } = checkAuth(
        action.payload.token,
        action.payload.refreshToken,
      );

      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        data: user,
      };
    }

    case UPDATE_TOKEN: {
      const { payloads }: any = action;

      return {
        ...state,
        token: payloads.token,
        data: payloads.userData,
      };
    }

    case UPDATE_REFRESH_TOKEN: {
      const { payloads }: any = action;

      return { ...state, refreshToken: payloads.refreshToken };
    }

    case CLEAR_AUTH:
      return {
        ...state,
        token: null,
        refreshToken: null,
        data: null,
        showSideBar: false,
      };

    case SHOW_SIDEBAR: {
      const { payloads }: any = action;
      return { ...state, showSideBar: payloads.showSideBar };
    }

    default:
      return state;
  }
}

// Action Creators
export function clearAuth(): ClearAuthAction {
  return { type: CLEAR_AUTH };
}

export function login(newtoken: string, newRefreshToken: string): LoginAction {
  return {
    type: USER_LOGGED_IN,
    payload: { token: newtoken, refreshToken: newRefreshToken },
  };
}

export function updateToken(newToken: any) {
  const { id, username, auth }: any = jwtDecode(newToken);

  return {
    type: UPDATE_TOKEN,
    payloads: {
      token: newToken,
      userData: { id, username, user: { id, username }, auth },
    },
  };
}

export function updateRefreshToken(newRefreshToken: any) {
  return {
    type: UPDATE_REFRESH_TOKEN,
    payloads: { refreshToken: newRefreshToken },
  };
}

export function showSideBar(show: any) {
  return {
    type: SHOW_SIDEBAR,
    payloads: { showSideBar: show },
  };
}
