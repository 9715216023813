import firebase from 'firebase';
import { DbState } from './types/db';

// Reducer
const config = {
  apiKey: 'AIzaSyD8Gm5juGKIpQ-BW1HzdlMb-0pMJokb7yA',
  authDomain: 'offline-test-46d84.firebaseapp.com',
  databaseURL: 'https://offline-test-46d84.firebaseio.com',
  projectId: 'offline-test-46d84',
  storageBucket: 'offline-test-46d84.appspot.com',
  messagingSenderId: '503040574486',
};
!firebase.apps.length ? firebase.initializeApp(config) : firebase.app();

const initialState: DbState = {
  db: firebase,
};

const store = (state = initialState, action: any): DbState => {
  switch (action.type) {
    default:
      return state;
  }
};

export default store;
