import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  Observable,
  from,
  split,
} from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

import { logout } from '../auth';
import { updateRefreshToken, updateToken } from '../reducers/user';
import store from '../store';
import mainUrl, { wsUrl } from './mainUrl';

const apiAuthToken = 'hHJMpLhoCJ6xEGPVuKr7gqd8zFZXHR13';

const genNewToken = async (refreshToken: string | null) => {
  return new Promise<string>(async (success, fail) => {
    try {
      const requestRefreshToken = await fetch(`${mainUrl}/token`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiAuthToken}`,
        },

        body: JSON.stringify({
          refreshToken,
        }),
      });

      const jsonToken = await requestRefreshToken.json();

      return success(jsonToken);
    } catch (e) {
      return fail(e);
    }
  });
};

const httpLink = new HttpLink({
  uri: `${mainUrl}/graphql`,
});

const wsLink = new WebSocketLink({
  uri: `${wsUrl}/graphql`,
  options: {
    reconnect: true,
  },
});

const authLink = new ApolloLink((operation, forward) => {
  const { headers } = operation.getContext();
  const { user } = store.getState();
  const token = user.token || null;

  operation.setContext({
    headers: {
      ...headers,
      Authorization: `Bearer ${apiAuthToken}`,
      'x-token': token,
    },
  });

  return forward(operation);
});

const linkErr = onError(
  ({ graphQLErrors, forward, operation, networkError }) => {
    if (graphQLErrors) {
      const notAuth = graphQLErrors.reduce((pre, cur) => {
        if (
          cur.message === 'Not authenticated' ||
          cur.message === 'Not Authorised!' ||
          cur.message ===
            'Access denied! You need to be authorized to perform this action!' ||
          cur.message.includes('Not authenticated')
        ) {
          return true;
        }

        return pre;
      }, false);

      if (notAuth) {
        return new Observable(observer => {
          const { user } = store.getState();
          const refreshToken = user.refreshToken || null;

          genNewToken(refreshToken)
            .then(refreshResponse => {
              const { token, refreshToken }: any = refreshResponse;
              store.dispatch(updateToken(token));
              store.dispatch(updateRefreshToken(refreshToken));

              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };

              forward(operation).subscribe(subscriber);
            })
            .catch(error => {
              console.log(error);
              logout();
              observer.error(error);
            });
        });
      }

      graphQLErrors.map(({ extensions, message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  },
);

const splitLink = split(
  ({ query }) => {
    const result = getMainDefinition(query);
    return (
      result.kind === 'OperationDefinition' &&
      result.operation === 'subscription'
    );
  },
  wsLink,
  from([linkErr, authLink, httpLink]),
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
    mutate: { errorPolicy: 'all' },
  },
});

export default client;
