import { Layout } from 'antd';
import { createBrowserHistory as createHistory } from 'history';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import {
  adminAuthRedirect,
  userIsAuthenticated as userIsAuthenticatedRedir,
} from '../auth';
import Loadable from '../components/Loadable';
import LoadingPage from '../components/LoadingPage';
import ScrollToTop from '../components/ScrollToTop';
const AsyncSideBar = Loadable({
  loader: () => import('../components/SideBar'),
});

const AsyncLogin = Loadable({ loader: () => import('../pages/Login') });

const AsyncHome = userIsAuthenticatedRedir(
  Loadable({ loader: () => import('../pages/Home') }),
);

const AsyncService = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/Service'),
  }),
);

const AsyncHousekeeper = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/Housekeeper'),
  }),
);

const AsyncUnoCoupon = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/UnoCoupon'),
  }),
);

const AsyncPdpaPolicy = Loadable({
  loader: () => import('../pages/pdpa/Policy'),
});

const AsyncPdpaRegister = Loadable({
  loader: () => import('../pages/pdpa/Register'),
});

const AsyncPdpaComplete = Loadable({
  loader: () => import('../pages/pdpa/Complete'),
});

// const AsyncNumaid = userIsAuthenticatedRedir(
//   Loadable({
//     loader: () => import('../pages/Numaid'),
//   }),
// );

// const AsyncOzone = userIsAuthenticatedRedir(
//   Loadable({
//     loader: () => import('../pages/Ozone'),
//   }),
// );

// const AsyncServiceOrder = userIsAuthenticatedRedir(
//   Loadable({
//     loader: () => import('../pages/WorkServiceOrder'),
//   }),
// );

// const AsyncFreelancer = userIsAuthenticatedRedir(
//   Loadable({
//     loader: () => import('../pages/FreelancerList'),
//   }),
// );

const AsyncPromoCode = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/PromoCode'),
  }),
);

const AsyncUnoServiceOrder = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/UnoService/order'),
  }),
);

const AsyncUnoServiceName = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/UnoService/service'),
  }),
);

const AsyncUnoServiceFreelancer = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/UnoService/freelancer'),
  }),
);

const AsyncUnoServiceCoupon = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/UnoService/coupon'),
  }),
);

const AsyncTermite = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/Service/termite'),
  }),
);

const AsyncInstall = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/Service/install'),
  }),
);

const AsyncProduct = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/Decor/Product'),
  }),
);

const AsyncFurpackage = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/Decor/Furpackage'),
  }),
);

const AsyncOrderProduct = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/Decor/Order/Product'),
  }),
);

const AsyncOrderPackage = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/Decor/Order/Package'),
  }),
);

const AsyncManageShippingCost = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/Decor/ManageShippingCost'),
  }),
);

const AsyncEditManageShippingCost = userIsAuthenticatedRedir(
  Loadable({
    loader: () =>
      import('../pages/Decor/ManageShippingCost/EditManageShippingCost'),
  }),
);

const AsyncCourse = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/Academy/Course'),
  }),
);

const AsyncOrderCourse = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/Academy/Order'),
  }),
);

const AsyncSellUnoServiceList = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/SellUnoService/List'),
  }),
);

const AsyncSellUnoServiceOrder = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/SellUnoService/Order'),
  }),
);

const AsyncSellUnoServiceGroup = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/SellUnoService/Group'),
  }),
);

const AsyncProductGroup = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/Decor/Group'),
  }),
);

const AsyncAdminAuth = userIsAuthenticatedRedir(
  adminAuthRedirect(
    Loadable({
      loader: () => import('../pages/Admin/auth'),
    }),
  ),
);

const AsyncUsers = userIsAuthenticatedRedir(
  adminAuthRedirect(
    Loadable({
      loader: () => import('../pages/Admin/Users'),
    }),
  ),
);

const AsyncReport = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/Report'),
  }),
);

const AsyncJobsRportPage = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/Numaid/pages/JobsRportPage'),
  }),
);

const AsyncUnoCouponSummary = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/UnoCoupon/summary'),
  }),
);

const AsyncSignatureServicePage = userIsAuthenticatedRedir(
  Loadable({
    loader: () => import('../pages/SignatureService'),
  }),
);

const AsyncNoMatch = Loadable({ loader: () => import('../pages/NoMatch') });

const routes = [
  {
    path: '/',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncHome,
  },
  {
    path: '/service',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncService,
  },
  {
    path: '/housekeeper',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncHousekeeper,
  },
  {
    path: '/unocoupon',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncUnoCoupon,
  },
  {
    path: '/unocoupon/summary',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncUnoCouponSummary,
  },
  {
    path: '/pdpa',
    exact: true,
    sidebar: () => null,
    main: AsyncPdpaPolicy,
  },
  {
    path: '/pdpa/register/:id',
    exact: true,
    sidebar: () => null,
    main: AsyncPdpaRegister,
  },
  {
    path: '/pdpa/complete',
    exact: true,
    sidebar: () => null,
    main: AsyncPdpaComplete,
  },
  // {
  //   path: '/numaid',
  //   exact: true,
  //   sidebar: AsyncSideBar,
  //   main: AsyncNumaid,
  // },
  // {
  //   path: '/ozone',
  //   exact: true,
  //   sidebar: AsyncSideBar,
  //   main: AsyncOzone,
  // },
  {
    path: '/serviceorder',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncUnoServiceOrder,
  },
  {
    path: '/service/:name',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncUnoServiceName,
  },
  {
    path: '/servicecoupon',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncUnoServiceCoupon,
  },
  {
    path: '/freelancer',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncUnoServiceFreelancer,
  },
  {
    path: '/promoCode',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncPromoCode,
  },
  {
    path: '/login',
    exact: true,
    sidebar: () => null,
    main: AsyncLogin,
  },
  {
    path: '/termite',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncTermite,
  },
  {
    path: '/install',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncInstall,
  },
  {
    path: '/product',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncProduct,
  },
  {
    path: '/furpackage',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncFurpackage,
  },
  {
    path: '/order_product',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncOrderProduct,
  },
  {
    path: '/order_package',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncOrderPackage,
  },
  {
    path: '/manageShippingCost',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncManageShippingCost,
  },
  {
    path: '/manageShippingCost/edit/:id',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncEditManageShippingCost,
  },
  {
    path: '/order_package',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncOrderPackage,
  },
  {
    path: '/academy/course',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncCourse,
  },
  {
    path: '/academy/orders',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncOrderCourse,
  },
  {
    path: '/sellUnoService/list',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncSellUnoServiceList,
  },
  {
    path: '/sellUnoService/orders',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncSellUnoServiceOrder,
  },
  {
    path: '/sellUnoService/group',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncSellUnoServiceGroup,
  },
  {
    path: '/product/group',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncProductGroup,
  },
  {
    path: '/auth',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncAdminAuth,
  },
  {
    path: '/users',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncUsers,
  },
  {
    path: '/report',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncReport,
  },
  {
    path: '/numaid/jobsreport',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncJobsRportPage,
  },
  {
    path: '/britania-signature-service',
    exact: true,
    sidebar: AsyncSideBar,
    main: AsyncSignatureServicePage,
  },
];

const history = createHistory();

const Routes = () => {
  return (
    <Router history={history}>
      <Suspense fallback={<LoadingPage />}>
        <ScrollToTop />
        <Layout style={{ minHeight: '100vh' }}>
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.sidebar}
              />
            ))}
          </Switch>

          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.main}
              />
            ))}

            <Route component={AsyncNoMatch} />
          </Switch>
        </Layout>
      </Suspense>
    </Router>
  );
};

export default connect()(Routes);
