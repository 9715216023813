import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { clearAuth } from './reducers/user';
import store, { RootState } from './store';

// const checkAuth = () => {
//   const token = localStorage.getItem('token');
//   const refreshToken = localStorage.getItem('refreshToken');
//   if (!token || !refreshToken) {
//     return false;
//   }

//   try {
//     const { exp } = decode(refreshToken);

//     if (exp < new Date().getTime() / 1000) {
//       return false;
//     }
//   } catch (e) {
//     return false;
//   }

//   return true;
// };

// const userIsAuthenticatedDefaults = {
//   authenticatedSelector: (state: RootState) => {
//     return state.user.data != null;
//   },
//   wrapperDisplayName: 'UserIsAuthenticated',
// };

// export const userIsAuthenticated = connectedRouterRedirect({
//   ...userIsAuthenticatedDefaults,
//   redirectPath: '/login',
// });

export const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: (state: RootState) => {
    return state.user.data != null;
  },
  // authenticatingSelector: state => state.user.isLoading,
  wrapperDisplayName: 'UserIsAuthenticated',
});

export const adminAuthRedirect = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: (state: RootState) =>
    state.user.data.auth.filter((data: any) => data === 'admin').length !== 0
      ? true
      : false,
  wrapperDisplayName: 'adminAuthRedirect',
  allowRedirectBack: false,
});

// export const userIsAuthenticated = connectedRouterRedirect({
//   redirectPath: '/login',
//   authenticatedSelector: (state: RootState) => {
//     return checkAuth() && state.user.data != null;
//   },
//   // authenticatingSelector: state => state.user.isLoading,
//   wrapperDisplayName: 'UserIsAuthenticated',
// });

export function logout() {
  const { dispatch } = store;
  dispatch(clearAuth());
}
