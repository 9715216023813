import { combineReducers } from 'redux';
import userReducer from './user';
import dbReducer from './db';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';

const persistConfig = {
  key: 'root',
  storage: localforage,
};

const AppReducer = combineReducers({
  user: persistReducer(persistConfig, userReducer),
  db: dbReducer,
});

export default AppReducer;
